<template>
  <div>
    <div class="mb-4 flex items-center justify-between">
      <h2>Auftragsübersicht</h2>
      <router-link
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2"
        to="/auftrag/neu"
      >
        Neuer Auftrag
      </router-link>
    </div>

    <dashboard-element>
      <dashboard-head title="Aufträge" />
      <dashboard-table class="p-3">
        <div v-for="auftrag in auftraege" :key="auftrag.id">
          <dashboard-card
            :content="auftrag.auftragsnummer"
            :link="`/auftrag/${auftrag.id}`"
            :title="auftrag.customer"
          ></dashboard-card>
        </div>
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import DashboardHead from "../../components/dashboard/DashboardHead.vue";
import DashboardTable from "../../components/dashboard/DashboardTable.vue";
import DashboardCard from "../../components/dashboard/DashboardCard.vue";
import DashboardElement from "../../components/dashboard/DashboardElement.vue";
import { supabase } from "../../plugins/supabase";

export default {
  components: {
    DashboardHead,
    DashboardTable,
    DashboardCard,
    DashboardElement
  },
  name: "Auftragsübersicht",
  data() {
    return {
      auftraege: []
    };
  },
  mounted() {
    this.loadInitialData();

    supabase
      .from("auftraege")
      .on("*", payload => {
        console.log("Change received!", payload);
        this.loadInitialData();
      })
      .subscribe();
  },
  methods: {
    createProduct() {
      console.log("NEW PRODUCT NOW PLZZZ");
    },
    async loadInitialData() {
      const { data: auftraege, error } = await supabase
        .from("auftraege")
        .select("*")
        .range(0, 9);

      error && console.log(error);

      this.auftraege = auftraege;
    }
  }
};
</script>
